<template>
  <div class="addLedgerDialog-page">
    <el-dialog
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm form-body"
      >
        <h3>
          分账方<span class="title-tips"
            >将部分交易资金分出的一方，这里指特约商户</span
          >
        </h3>
        <el-form-item label="分账方：">
          <span>{{ ruleForm.ledgerMchName }}</span>
        </el-form-item>
        <el-form-item label="分账方商户号：">
          <span>{{ ruleForm.ledgerMchId }}</span>
        </el-form-item>
        <el-form-item label="分账方比例：">
          <span>{{ ruleForm.maxLedgerRatio }}%</span>
        </el-form-item>
        <h3>
          分账接收方<span class="title-tips"
            >接收资金的一方，可以是商户，可以是个人。目前仅支持添加商户</span
          >
        </h3>
        <el-form-item
          label="商户号："
          prop="ledgerReceiveId"
          :rules="
            dialogTitle === '编辑分账接收方'
              ? [{ require: false }]
              : rules.ledgerReceiveId
          "
        >
          <span v-if="dialogTitle === '编辑分账接收方'">{{
            ruleForm.ledgerReceiveId
          }}</span>
          <el-input
            v-else
            v-model="ruleForm.ledgerReceiveId"
            :disabled="dialogTitle === '编辑分账接收方'"
            clearable
            placeholder="请输入商户号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="商户全称："
          prop="ledgerReceiveName"
          :rules="
            dialogTitle === '编辑分账接收方'
              ? [{ require: false }]
              : rules.ledgerReceiveName
          "
        >
          <span v-if="dialogTitle === '编辑分账接收方'">{{
            ruleForm.ledgerReceiveName
          }}</span>
          <el-input
            v-else
            v-model="ruleForm.ledgerReceiveName"
            :disabled="dialogTitle === '编辑分账接收方'"
            clearable
            placeholder="请输入商户全称"
          ></el-input>
        </el-form-item>
        <h3>
          分账设置<span class="title-tips">分账设置变更后次日零时生效</span>
        </h3>
        <div
          v-for="(node, nodeIndex) in ruleForm.dimensionSettingsList"
          :key="nodeIndex"
          class="fzsz-box"
        >
          <el-form-item :label="node.business + '：'">
            <el-switch v-model="node.switchValue"></el-switch>
          </el-form-item>
          <div class="inner-box" v-if="node.switchValue">
            <el-form-item
              label="分账策略："
              label-width="84"
              :prop="
                'dimensionSettingsList.' +
                nodeIndex +
                '.ledgerReceiveStrategyValue'
              "
              :rules="rules.ledgerReceiveStrategyValue"
            >
              <el-radio-group
                v-model="node.ledgerReceiveStrategyValue"
                @input="changeFzclRadioFun($event, node)"
              >
                <el-radio
                  v-for="(fzcl, fzclIndex) in node.ledgerReceiveStrategyList"
                  :key="fzclIndex"
                  :label="fzcl.value"
                  >{{ fzcl.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="分账模式："
              label-width="84"
              :prop="
                node.ledgerReceiveStrategyValue == 0
                  ? 'dimensionSettingsList.' +
                    nodeIndex +
                    '.ledgerReceiveModelValue'
                  : 'wechatMchLedgerReceiveLines'
              "
              :rules="
                node.ledgerReceiveStrategyValue == 0
                  ? rules.ledgerReceiveModelValue
                  : rules.wechatMchLedgerReceiveLines
              "
            >
              <!-- 统一分账 -->
              <el-radio-group
                v-if="node.ledgerReceiveStrategyValue == 0"
                v-model="node.ledgerReceiveModelValue"
                @input="changeFzmsRadioFun($event, node)"
              >
                <div
                  v-for="(item, index) in node.ledgerReceiveModelList"
                  :key="index"
                  style="margin-bottom: 22px"
                >
                  <el-radio :label="item.value">
                    {{ item.label }}
                    <el-input
                      v-model="item.ledgerReceiveFeeValue"
                      clearable
                      maxlength="2"
                      :disabled="node.ledgerReceiveModelValue != item.value"
                      :placeholder="item.message"
                      :class="{
                        ml36: item.value == 1,
                        ml8: item.value == 2,
                      }"
                      @change="changeLedgerReceiveFeeFun($event, item)"
                    ></el-input>
                    <span style="margin-left: 10px">{{
                      item.value == 1 ? '%' : '元'
                    }}</span>
                    <div
                      v-if="
                        item.value == 1 &&
                        item.ledgerReceiveFeeValue &&
                        item.ledgerReceiveFeeValue >= ruleForm.maxLedgerRatio
                      "
                      class="over-tips"
                      style="padding-top: 10px"
                    >
                      分账比例不能超过特约商户配置的最大比例
                    </div>
                  </el-radio>
                </div>
              </el-radio-group>
              <!-- 按线路分账 -->
              <template v-if="node.ledgerReceiveStrategyValue == 1">
                <template v-if="ruleForm.wechatMchLedgerReceiveLines.length">
                  <div
                    v-for="(
                      line, lineIndex
                    ) in ruleForm.wechatMchLedgerReceiveLines"
                    :key="lineIndex"
                  >
                    <div
                      class="fzms-box"
                      :class="lineIndex !== 0 ? 'not-Pad' : ''"
                    >
                      <el-input v-model="line.lineName" readonly> </el-input>
                      <el-select
                        v-model="line.ledgerReceiveModel"
                        @change="changeModelSelectFun(line)"
                      >
                        <el-option
                          v-for="(item, index) in node.ledgerReceiveModelList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                      <el-input
                        v-model="line.ledgerReceiveFee"
                        clearable
                        maxlength="2"
                        :placeholder="
                          line.ledgerReceiveModel == 1
                            ? '请输入1-30的整数'
                            : '请输入正整数'
                        "
                        @change="changeLineLedgerReceiveFeeFun($event, line)"
                      ></el-input>
                      <span style="margin: 0 10px">{{
                        line.ledgerReceiveModel == 1
                          ? '%'
                          : line.ledgerReceiveModel == 2
                          ? '元'
                          : ''
                      }}</span>
                      <el-button
                        style="color: #f56c6c"
                        type="text"
                        @click="deleteLineFun(lineIndex)"
                        >删除</el-button
                      >
                    </div>
                    <div
                      v-if="
                        line.ledgerReceiveModel == 1 &&
                        line.ledgerReceiveFee &&
                        line.ledgerReceiveFee >= ruleForm.maxLedgerRatio
                      "
                      class="over-tips"
                    >
                      分账比例不能超过特约商户配置的最大比例
                    </div>
                  </div>
                </template>
                <el-button type="text" @click="addLineFun">添加线路</el-button>
              </template>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveFun">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加线路"
      :visible.sync="lineDialogVisible"
      :close-on-click-modal="false"
      width="20%"
    >
      <el-select
        v-model="lineName"
        v-loadMore="loadMore"
        value-key="id"
        @change="changeLineSelectFun"
      >
        <el-option
          v-for="(item, index) in lineList"
          :key="index"
          :label="item.name"
          :value="item"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lineDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmLineFun">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveWechatMchLedgerReceiveAPI,
  updateWechatMchLedgerReceiveAPI,
  linePageAPI,
} from "@/api/lib/api.js";
const reg1 = /^([12][0-9]|30|[1-9])$/; //1-30整数
const reg2 = /^[1-9]\d*$/; //正整数
export default {
  name: "",
  components: {},
  props: {},
  directives: {
    //滚动加载
    loadMore: {
      bind (el, binding) {
        // 获取element，定义scroll
        let select_dom = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        select_dom.addEventListener("scroll", function () {
          let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (height) {
            binding.value();
          }
        });
      },
    },
  },
  data () {
    let jument = (rule, value, callback) => {
      if (this.ruleForm.ledgerReceiveStrategy == 1) {
        let flag = true;
        if (this.ruleForm.wechatMchLedgerReceiveLines.length > 0) {
          this.ruleForm.wechatMchLedgerReceiveLines.forEach((item) => {
            if (!item.ledgerReceiveModel) {
              flag = false;
              return;
            }
            if (!item.ledgerReceiveFee) {
              flag = false;
              return;
            }
          });
          if (!flag) {
            callback(new Error("线路分账模式内容必填"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请添加线路"));
        }
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dialogTitle: "",
      lineDialogVisible: false,
      ledgerReceiveFeeFlag: false,
      ruleForm: {
        ledgerMchName: "",//分账商户名称
        ledgerMchId: "",//分账商户号
        ledgerReceiveId: "",//分账接收方账户ID
        ledgerReceiveName: "",//分账接收方名称
        ledgerAppId: "",//分账商户应用编号
        wechatMchLedgerReceiveLines: [],//----城际专车线路分账配置集合
        dimensionSettingsList: [
          {
            business: "城际专车",
            ledgerBusiness: 1,//分账业务类型1-城际专车 2-汽车票 4-包车 5-租车
            switchValue: false,//开关
            ledgerReceiveStrategyValue: 0,//
            ledgerReceiveStrategyKey: "ledgerReceiveStrategy",//分账策略 0-统一分账 1-按线路分账
            ledgerReceiveStrategyList: [
              { label: "统一分账", value: 0 },
              { label: "按线路分账", value: 1 },
            ],
            ledgerReceiveModelValue: 1,
            ledgerReceiveModelKey: "ledgerReceiveModel",//分账接收方模式 1-按比例分账 2-按固定金额分账
            ledgerReceiveModelList: [
              {
                label: "按比例",
                value: 1,
                ledgerReceiveFeeValue: "",
                ledgerReceiveFeeKey: "ledgerReceiveFee",
                message: "请输入1-30的整数",
              },
              {
                label: "按固定金额",
                value: 2,
                ledgerReceiveFeeValue: "",
                ledgerReceiveFeeKey: "ledgerReceiveFee",
                message: "请输入正整数",
              },
            ],
          },
          {
            business: "汽车票",
            ledgerBusiness: 2,
            switchValue: false,//开关
            ledgerReceiveStrategyValue: 0,
            ledgerReceiveStrategyKey: "carLedgerReceiveStrategy",
            ledgerReceiveStrategyList: [{ label: "统一分账", value: 0 }],
            ledgerReceiveModelValue: 1,
            ledgerReceiveModelKey: "carLedgerReceiveModel",
            ledgerReceiveModelList: [
              {
                label: "按比例",
                value: 1,
                ledgerReceiveFeeValue: "",
                // ledgerReceiveFeeKey: "carLedgerReceiveFee",
                message: "请输入1-30的整数",
              },
              {
                label: "按固定金额",
                value: 2,
                ledgerReceiveFeeValue: "",
                // ledgerReceiveFeeKey: "carLedgerReceiveFee",
                message: "请输入正整数",
              },
            ],
          },
          {
            business: "包车",
            ledgerBusiness: 4,
            switchValue: false,//开关
            ledgerReceiveStrategyValue: 0,
            ledgerReceiveStrategyKey: "carLedgerReceiveStrategy",
            ledgerReceiveStrategyList: [{ label: "统一分账", value: 0 }],
            ledgerReceiveModelValue: 1,
            ledgerReceiveModelKey: "carLedgerReceiveModel",
            ledgerReceiveModelList: [
              {
                label: "按比例",
                value: 1,
                ledgerReceiveFeeValue: "",
                ledgerReceiveFeeKey: "carLedgerReceiveFee",
                message: "请输入1-30的整数",
              },
            ],
          },
          {
            business: "租车",
            ledgerBusiness: 5,
            switchValue: false,//开关
            ledgerReceiveStrategyValue: 0,
            ledgerReceiveStrategyKey: "carLedgerReceiveStrategy",
            ledgerReceiveStrategyList: [{ label: "统一分账", value: 0 }],
            ledgerReceiveModelValue: 1,
            ledgerReceiveModelKey: "carLedgerReceiveModel",
            ledgerReceiveModelList: [
              {
                label: "按比例",
                value: 1,
                ledgerReceiveFeeValue: "",
                ledgerReceiveFeeKey: "carLedgerReceiveFee",
                message: "请输入1-30的整数",
              },
            ],
          },
        ],
        maxLedgerRatio: "",//分账比例
      },
      rules: {
        ledgerReceiveId: [
          { required: true, message: "请输入商户号", trigger: "blur" },
        ],
        ledgerReceiveName: [
          { required: true, message: "请输入商户全称", trigger: "blur" },
        ],
        ledgerReceiveModelValue: [
          { required: true, message: "请选择分账模式", trigger: "change" },
        ],
        wechatMchLedgerReceiveLines: [
          { required: true, message: "请选择分账模式", trigger: "change" },
          { validator: jument, trigger: "change" }
        ],
        ledgerReceiveStrategyValue: [
          { required: true, message: "请选择分账策略", trigger: "change" },
        ],
      },
      lineName: "",
      lineId: "",
      total: 0,
      lineParams: {
        currentPage: 1,
        pageSize: 10,
      },
      lineList: [],
      // SERVICE_PROVIDER：服务商
      // STORE：门店
      // STAFF：员工
      // STORE_OWNER：店主
      // PARTNER：合作伙伴
      // HEADQUARTER：总部
      // BRAND：品牌方
      // DISTRIBUTOR：分销商
      // USER：用户
      // SUPPLIER： 供应商
      // CUSTOM：自定义
      relationTypeList: [{ label: "服务商", value: "SERVICE_PROVIDER" }],
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible (val) {
      if (!val) {
        this.ruleForm = this.$options.data().ruleForm;
      }
    },
    lineDialogVisible (val) {
      if (!val) {
        this.lineName = this.$options.data().lineName;
        this.lineId = this.$options.data().lineId;
        this.lineParams = this.$options.data().lineParams;
        this.lineList = [];
      }
    },
  },
  created () { },
  mounted () { },
  methods: {
    openDialogFun (title, row) {
      if (title === "编辑分账接收方") {
        this.ruleForm.id = row.id;
        this.ruleForm.ledgerReceiveId = row.ledgerReceiveId;
        this.ruleForm.ledgerReceiveName = row.ledgerReceiveName;
        this.ruleForm.wechatMchLedgerReceiveLines = row.kkWechatLedgerReceiveBusinessVOS.find(item => item.ledgerBusiness == 1)?.wechatMchLedgerReceiveLines || [];//城际专车才有线路分账
        //回显
        this.ruleForm.dimensionSettingsList.forEach((item, index) => {
          //唯一的联系是业务类型：ledgerBusiness
          let obj = row.kkWechatLedgerReceiveBusinessVOS.find(element => element.ledgerBusiness == item.ledgerBusiness);
          if (obj && obj.ledgerBusiness) {
            item.id = obj.id;
            item.ledgerReceiveFee = obj.ledgerReceiveFee;
            item.ledgerReceiveId = obj.ledgerReceiveId;
            item.switchValue = obj.status == 1;
            item.ledgerReceiveModelValue = obj.ledgerReceiveModel;
            item.ledgerReceiveStrategyValue = obj.ledgerReceiveStrategy;
            if (obj.ledgerReceiveModel == 1) {
              item.ledgerReceiveModelList[0].ledgerReceiveFeeValue =
                obj.ledgerReceiveFee;
            } else if (obj.ledgerReceiveModel == 2) {
              item.ledgerReceiveModelList[1].ledgerReceiveFeeValue =
                obj.ledgerReceiveFee;
            }
          }


          // this.ruleForm[item.ledgerReceiveStrategyKey] =
          //   item.ledgerReceiveStrategyValue;            
          // switch (index) {
          //   case 0:
          //     // item.ledgerReceiveModelValue = row.ledgerReceiveModel;
          //     // item.ledgerReceiveStrategyValue = row.ledgerReceiveStrategy;
          //     // if (row.ledgerReceiveModel == 1) {
          //     //   item.ledgerReceiveModelList[0].ledgerReceiveFeeValue =
          //     //     row.ledgerReceiveFee;
          //     // } else if (row.ledgerReceiveModel == 2) {
          //     //   item.ledgerReceiveModelList[1].ledgerReceiveFeeValue =
          //     //     row.ledgerReceiveFee;
          //     // }
          //     break;
          //   case 1:
          //     item.ledgerReceiveModelValue = row.carLedgerReceiveModel;
          //     item.ledgerReceiveStrategyValue = row.carLedgerReceiveStrategy;
          //     if (row.carLedgerReceiveModel == 1) {
          //       this.ruleForm.dimensionSettingsList[1].ledgerReceiveModelList[0].ledgerReceiveFeeValue =
          //         row.carLedgerReceiveFee;
          //     } else if (row.carLedgerReceiveModel == 2) {
          //       this.ruleForm.dimensionSettingsList[1].ledgerReceiveModelList[1].ledgerReceiveFeeValue =
          //         row.carLedgerReceiveFee;
          //     }
          //     break;
          //   default:
          //     break;
          // }
        });
        // //城际专车分账模式
        // this.ruleForm.ledgerReceiveModel = row.ledgerReceiveModel;
        // //城际专车分账策略
        // this.ruleForm.ledgerReceiveStrategy = row.ledgerReceiveStrategy;
        // //汽车票分账模式
        // this.ruleForm.carLedgerReceiveModel = row.carLedgerReceiveModel;
        // //汽车票分账策略
        // this.ruleForm.carLedgerReceiveStrategy = row.carLedgerReceiveStrategy;
        // //城际专车输入框值
        // this.ruleForm.ledgerReceiveFee = row.ledgerReceiveFee;
        // //汽车票输入框值
        // this.ruleForm.carLedgerReceiveFee = row.carLedgerReceiveFee;
      }
      console.log("行数据：", row);
      this.ruleForm.ledgerMchName = row.mchName;
      this.ruleForm.ledgerMchId = row.mchId;
      this.ruleForm.maxLedgerRatio = row.maxLedgerRatio;
      this.ruleForm.ledgerAppId = row.appId;
      this.ruleForm.channelNo = row.channelNo;
      this.ruleForm.kkWechatLedgerId = row.id,//商户分账管理id
        //分账策略
        this.ruleForm.dimensionSettingsList.forEach((item) => {
          this.ruleForm[item.ledgerReceiveStrategyKey] =
            item.ledgerReceiveStrategyValue;
        });
      this.dialogTitle = title;
      this.dialogVisible = true;
    },
    /**
     * @description 校验比例/固定金额
     */
    changeLedgerReceiveFeeFun (value, item) {
      if (value === "") {
        this.$message.warning(item.message);
        this.ruleForm[item.ledgerReceiveFeeKey] = "";
        this.ledgerReceiveFeeFlag = true;
      } else if (item.value == 1 && !reg1.test(value)) {
        this.$message.warning(item.message);
        this.ruleForm[item.ledgerReceiveFeeKey] = "";
        item.ledgerReceiveFeeValue = "";
        this.ledgerReceiveFeeFlag = true;
      } else if (item.value == 2 && !reg2.test(value)) {
        this.$message.warning(item.message);
        this.ruleForm[item.ledgerReceiveFeeKey] = "";
        item.ledgerReceiveFeeValue = "";
        this.ledgerReceiveFeeFlag = true;
      } else {
        this.ruleForm[item.ledgerReceiveFeeKey] = value;
        this.ledgerReceiveFeeFlag = false;
      }
    },
    changeLineLedgerReceiveFeeFun (value, line) {
      let message =
        line.ledgerReceiveModel == 1 ? "请输入1-30的整数" : "请输入正整数";
      if (value === "") {
        this.$message.warning(message);
        this.ledgerReceiveFeeFlag = true;
      } else if (!line.ledgerReceiveModel) {
        this.$message.warning("请选择线路分账模式");
        line.ledgerReceiveFee = "";
        this.ledgerReceiveFeeFlag = true;
      } else if (line.ledgerReceiveModel == 1 && !reg1.test(value)) {
        this.$message.warning(message);
        line.ledgerReceiveFee = "";
        this.ledgerReceiveFeeFlag = true;
      } else if (line.ledgerReceiveModel == 2 && !reg2.test(value)) {
        this.$message.warning(message);
        line.ledgerReceiveFee = "";
        this.ledgerReceiveFeeFlag = true;
      } else {
        line.ledgerReceiveFee = value;
        this.ledgerReceiveFeeFlag = false;
      }
    },
    changeModelSelectFun (line) {
      line.ledgerReceiveFee = "";
    },
    /**
     * @description 切换分账模式
     */
    changeFzmsRadioFun (val, item) {
      item.ledgerReceiveModelList.forEach((e) => {
        this.$set(e, "ledgerReceiveFeeValue", "");
      });
      // if (item.business === "城际专车") {
      //   this.ruleForm.ledgerReceiveFee = "";
      // } else if (item.business === "汽车票") {
      //   this.ruleForm.carLedgerReceiveFee = "";
      // }
      // this.ruleForm[item.ledgerReceiveModelKey] = val;
    },
    /**
     * @description 切换分账策略
     */
    changeFzclRadioFun (val, item) {
      item.ledgerReceiveModelList.forEach((e) => {
        this.$set(e, "ledgerReceiveFeeValue", "");
      });
      this.$set(item, "ledgerReceiveModelValue", 1);
      this.ruleForm[item.ledgerReceiveStrategyKey] = val;
      if (this.dialogTitle === "添加分账接收方") {
        this.ruleForm.wechatMchLedgerReceiveLines = [];
      }
      delete this.ruleForm.ledgerReceiveModel;
      delete this.ruleForm.ledgerReceiveFee;
    },
    /**
     * @description 选择线路
     */
    changeLineSelectFun (item) {
      this.lineName = item.name;
      this.lineId = item.id;
    },
    /**
     * @description 添加线路
     */
    addLineFun () {
      this.getLinePage();
      this.lineDialogVisible = true;
    },
    /**
     * @description 获取线路列表
     */
    getLinePage () {
      linePageAPI(this.lineParams).then((res) => {
        if (res.code == "SUCCESS") {
          this.lineList = [...this.lineList, ...res.data.list];
          this.total = res.data.total;
        }
      });
    },
    loadMore () {
      if (this.lineParams.currentPage * this.lineParams.pageSize >= this.total)
        return;
      this.lineParams.currentPage++;
      this.getLinePage();
    },
    deleteLineFun (index) {
      this.ruleForm.wechatMchLedgerReceiveLines.splice(index, 1);
    },
    /**
     * @description 确认选择线路
     */
    confirmLineFun () {
      let data = {
        lineName: this.lineName,
        lineId: this.lineId,
        ledgerReceiveModel: "",
        ledgerReceiveFee: "",
      };
      if (this.ruleForm.wechatMchLedgerReceiveLines.length) {
        let wechatMchLedgerReceiveLines = this.ruleForm.wechatMchLedgerReceiveLines;
        let result = wechatMchLedgerReceiveLines.some(item => item.lineId == this.lineId);
        if (result) {
          this.$message.warning("不能添加相同线路");
        } else {
          this.ruleForm.wechatMchLedgerReceiveLines.push(data);
          this.lineDialogVisible = false;
        }
      } else {
        this.ruleForm.wechatMchLedgerReceiveLines.push(data);
        this.lineDialogVisible = false;
      }
    },
    /**
     * @description 保存
     */
    saveFun () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ledgerReceiveFeeFlag) {
            this.ledgerReceiveFeeFlag = false;
            return;
          }
          // if (
          //   (this.ruleForm.ledgerReceiveStrategy == 0 &&
          //     !this.ruleForm.ledgerReceiveFee) ||
          //   (this.ruleForm.carLedgerReceiveStrategy == 0 &&
          //     !this.ruleForm.carLedgerReceiveFee)
          // ) {
          //   this.$message.error("请输入比例/固定金额");
          //   return;
          // }
          if (
            this.ruleForm.ledgerReceiveStrategy == 1 &&
            !this.ruleForm.wechatMchLedgerReceiveLines.length
          ) {
            this.$message.error("请添加线路");
            return;
          }
          if (this.ruleForm.ledgerReceiveStrategy == 1) {
            let flag = true;
            this.ruleForm.wechatMchLedgerReceiveLines.forEach((item) => {
              if (!item.ledgerReceiveModel) {
                this.$message.error("请选择线路分账模式");
                flag = false;
                return;
              }
              if (!item.ledgerReceiveFee) {
                this.$message.error("请输入线路分账比例/固定金额");
                flag = false;
                return;
              }
            });
            if (!flag) return;
          }
          console.log("初始值---", this.ruleForm);
          let params = Object.assign({}, JSON.parse(JSON.stringify(this.ruleForm)));
          //拼装数据 
          params.kkWechatLedgerReceiveBusinessDTOS = [];
          let result = false;
          this.ruleForm.dimensionSettingsList.map(item => {
            let obj = {};
            obj = {
              ledgerReceiveStrategy: item.ledgerReceiveStrategyValue,//分账策略
              ledgerBusiness: item.ledgerBusiness,
              status: item.switchValue,
              ledgerReceiveModel: item.ledgerReceiveModelValue,
            };
            if (this.dialogTitle === "编辑分账接收方") {
              obj.id = item.id;
              obj.ledgerReceiveFee = item.ledgerReceiveFee;
              obj.ledgerReceiveId = item.ledgerReceiveId;
            }
            if (item.ledgerBusiness == 1 && item.ledgerReceiveStrategyValue == 1) {
              obj.wechatMchLedgerReceiveLines = params.wechatMchLedgerReceiveLines;
            } else {
              obj.ledgerReceiveFee = item.ledgerReceiveModelList[item.ledgerReceiveModelValue - 1]?.ledgerReceiveFeeValue;//分账比例/分账金额
            }

            params.kkWechatLedgerReceiveBusinessDTOS.push(obj);
            //按比例分账不能超过最大分账比例
            result = params.kkWechatLedgerReceiveBusinessDTOS.some(item => item.ledgerReceiveFee >= this.ruleForm.maxLedgerRatio && item.ledgerReceiveModel == 1);
          })
          //如果城际专车是按线路分账
          let result1 = this.ruleForm.wechatMchLedgerReceiveLines.some(item => item.ledgerReceiveModel == 1 && item.ledgerReceiveFee >= this.ruleForm.maxLedgerRatio);
          if (result1) {
            this.$message.error("分账比例不能超过特约商户配置的最大比例");
            return;
          } else {
            if (result) {
              this.$message.error("分账比例不能超过特约商户配置的最大比例");
              return;
            }
          }

          delete params.dimensionSettingsList;
          // params.wechatMchLedgerReceiveLines =
          //   params.ledgerReceiveStrategy == 1
          //     ? params.wechatMchLedgerReceiveLines
          //     : [];
          if (this.dialogTitle === "添加分账接收方") {
            saveWechatMchLedgerReceiveAPI(params).then((res) => {
              if (res.code === "SUCCESS") {
                this.dialogVisible = false;
                if (this.$parent.isShowDetails === undefined) {
                  this.$parent.getQueryWechatMchLedgerInfo();
                } else {
                  this.$parent.getQueryWechatMchLedgerPage();
                }
              }
            });
          } else if (this.dialogTitle === "编辑分账接收方") {
            updateWechatMchLedgerReceiveAPI(params.kkWechatLedgerReceiveBusinessDTOS).then((res) => {
              if (res.code === "SUCCESS") {
                this.dialogVisible = false;
                this.$parent.getQueryWechatMchLedgerInfo();
              }
            });
          }
        }
      });
    },
    // changeSelect() {
    //   this.ruleForm.ledgerReceiveFee = "";
    // },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-form {
  .el-form-item {
    margin-bottom: 16px !important;
  }
}
.form-body {
  height: 60vh;
  overflow-y: auto;
}
.addLedgerDialog-page {
  .el-input {
    width: 70%;
  }
  .fzsz-box {
    box-sizing: border-box;
    .over-tips {
      text-align: end;
      color: #d9001b;
      line-height: 20px;
    }
    // margin-bottom: 22px;
    .inner-box {
      margin-left: 140px;
      background-color: #f2f2f2;
      padding: 10px 20px 0;
    }
    .ml36 {
      margin-left: 36px;
    }
    .ml8 {
      margin-left: 8px;
    }
    .el-input {
      width: 60%;
    }
    .fzms-box {
      display: flex;
      .el-input {
        width: 28%;
      }
      .el-select {
        width: 22%;
        margin: 0 10px;
      }
    }
    .not-Pad {
      padding-left: 80px !important;
    }
  }
  .title-tips {
    color: #aaaaaa;
    font-size: 14px;
    font-weight: normal;
    margin-left: 16px;
  }
}
</style>
