<template>
  <!-- 新增分账方 v7.8.1版本更新：无编辑功能-->
  <div class="newAddDialog-page">
    <el-dialog
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="分账方：" prop="mchName">
          <el-select
            v-model="ruleForm.mchName"
            v-loadMore="loadMore"
            :disabled="dialogTitle === '编辑分账方'"
            clearable
            placeholder="请选择分账方"
            value-key="mchId"
            @change="changeMchNameFun"
          >
            <el-option
              v-for="(item, index) in mchNameList"
              :key="index"
              :label="item.mchName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="特约商户号：" prop="mchId">
          <el-input
            v-model="ruleForm.mchId"
            disabled
            placeholder="请选择分账方"
          ></el-input>
        </el-form-item>
        <el-form-item label="最大分账比例：" prop="maxLedgerRatio">
          <span v-if="ruleForm.maxLedgerRatio" style="margin-right: 8px">{{
            ruleForm.maxLedgerRatio + "%"
          }}</span>
          <el-button
            v-if="dialogTitle === '新增分账方'"
            :loading="btnLoading"
            type="primary"
            @click="searchProportionFun"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveFun">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveWechatMchLedgerAPI,
  queryWechatCertificatePageAPI,
  queryWechatMaxLedgerRatioByIdAPI,
  updateWechatMchLedgerAPI,
} from "@/api/lib/api.js";
export default {
  directives: {
    //滚动加载
    loadMore: {
      bind(el, binding) {
        // 获取element，定义scroll
        let select_dom = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        select_dom.addEventListener("scroll", function () {
          let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (height) {
            binding.value();
          }
        });
      },
    },
  },
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      btnLoading: false,
      ruleForm: {
        mchName: "",
        mchId: "",
        maxLedgerRatio: "",
      },
      rules: {
        mchName: [
          { required: true, message: "请选择分账方", trigger: "change" },
        ],
        // mchId: [
        //   { required: true, message: "请选择企业名称", trigger: "change" },
        // ],
        maxLedgerRatio: [
          { required: true, message: "请查询最大分账比例", trigger: "change" },
        ],
      },
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 10,
        merchantType: 1,
        mchStatus: 0,
      },
      mchNameList: [], //企业名称
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        Object.assign(this.$data, this.$options.data());
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    openDialogFun(title, details) {
      if (title === "编辑分账方") {
        let {
          id,
          mchName,
          mchId,
          appId,
          channelNo,
          merchantType,
          maxLedgerRatio
        } = details;
        const ruleForm = this.ruleForm;
        ruleForm.id = id;
        ruleForm.mchName = mchName;
        ruleForm.mchId = mchId;
        ruleForm.appId = appId;
        ruleForm.merchantType = merchantType;
        ruleForm.channelNo = channelNo;
        ruleForm.maxLedgerRatio = maxLedgerRatio;
      }
      this.getQueryWechatCertificatePage();
      this.dialogTitle = title;
      this.dialogVisible = true;
    },
    /**
     * @description 保存
     */
    saveFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle === "新增分账方") {
            saveWechatMchLedgerAPI(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS" && res.data) {
                this.dialogVisible = false;
                this.$parent.getQueryWechatMchLedgerPage();
              }
            });
          } else if (this.dialogTitle === "编辑分账方") {
            updateWechatMchLedgerAPI(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS" && res.data) {
                this.dialogVisible = false;
                this.$parent.getQueryWechatMchLedgerInfo();
              }
            });
          }
        }
      });
    },
    /**
     * @description 获取企业名称
     */
    getQueryWechatCertificatePage() {
      queryWechatCertificatePageAPI(this.params).then((res) => {
        if (res.code == "SUCCESS") {
          this.mchNameList = [...this.mchNameList, ...res.data.list];
          this.total = res.data.total;
        }
      });
    },
    changeMchNameFun(val) {
      this.ruleForm.mchId = val.mchId;
      this.ruleForm.mchName = val.mchName;
      this.ruleForm.appId = val.appId;
      this.ruleForm.merchantType = val.merchantType;
      this.ruleForm.channelNo = val.channelNo;
    },
    //滚动加载
    loadMore() {
      if (this.params.currentPage * this.params.pageSize >= this.total) return;
      this.params.currentPage++;
      this.getQueryWechatCertificatePage();
    },
    /**
     * @description 查询最大分账比例
     */
    searchProportionFun() {
      if (!this.ruleForm.mchId) {
        this.$message.warning("请先选择分账方");
        return;
      }
      this.btnLoading = true;
      queryWechatMaxLedgerRatioByIdAPI({ id: this.ruleForm.mchId })
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.ruleForm.maxLedgerRatio = res.data;
          }
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.newAddDialog-page {
  .el-input,
  .el-select {
    width: 80%;
  }
  .fzsj-form {
    .el-select {
      width: 65%;
    }
  }
}
</style>
