var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "platformDetails-page" },
    [
      _c("div", { staticClass: "btn-box", on: { click: _vm.backFun } }, [
        _c("i", { staticClass: "iconfont its_ba_fanhui" }),
      ]),
      _c("div", { staticClass: "ledger-box content-box" }, [
        _c("div", { staticClass: "ledger-box-header" }, [
          _c("span", { staticClass: "tip-text" }, [_vm._v("分账方")]),
          _c(
            "div",
            [
              _vm.listFind("更新分账比例")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.updateProportionFun },
                    },
                    [_vm._v("更新分账比例")]
                  )
                : _vm._e(),
              _vm.listFind("添加分账接收方")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addLedgerFun },
                    },
                    [_vm._v("添加分账接收方")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "ledger-content-outFlex" },
          _vm._l(_vm.ledgerList, function (item, index) {
            return _c("div", { key: index, staticClass: "ledger-content" }, [
              _c("div", [_vm._v(_vm._s(item.label))]),
              item.value == "maxLedgerRatio"
                ? _c("div", [
                    _vm._v(
                      " " + _vm._s(_vm.detailsInfo[item.value] + "%") + " "
                    ),
                  ])
                : _c("div", [_vm._v(_vm._s(_vm.detailsInfo[item.value]))]),
            ])
          }),
          0
        ),
      ]),
      _vm._l(_vm.receiverList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "receiver-box content-box" },
          [
            _c("div", { staticClass: "ledger-box-header" }, [
              _c("span", { staticClass: "tip-text" }, [_vm._v("分账接收方")]),
              _c(
                "div",
                [
                  _vm.listFind("编辑")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editReceiverFun(item)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  _vm.listFind("删除")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteReceiverFun(item)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "receiver-content" }, [
              _c("h4", [_vm._v("基本信息")]),
              _c("div", { staticClass: "basic-information-top" }, [
                _c("div", [
                  _c("span", [_vm._v("商户全称：")]),
                  _c("span", [_vm._v(_vm._s(item.ledgerReceiveName))]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("商户号：")]),
                  _c("span", [_vm._v(_vm._s(item.ledgerReceiveId))]),
                ]),
              ]),
              _c("h4", [_vm._v("分账信息")]),
              _c(
                "div",
                { staticClass: "dimension-information-flex" },
                _vm._l(
                  item.kkWechatLedgerReceiveBusinessVOS,
                  function (info, infoIndex) {
                    return _c(
                      "div",
                      { key: infoIndex, staticClass: "dimension-information" },
                      [
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.analyzeName(info.ledgerBusiness))),
                        ]),
                        info.status == 1
                          ? [
                              _c("div", [
                                _c("span", [_vm._v("分账策略：")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLedgerReceiveStrategy(
                                        info.ledgerReceiveStrategy
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              info.ledgerReceiveStrategy == 0
                                ? [
                                    _c("div", [
                                      _c("span", [_vm._v("分账模式：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLedgerReceiveModel(
                                              info.ledgerReceiveModel
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            info.ledgerReceiveModel == 1
                                              ? "比例分账："
                                              : "分账金额："
                                          )
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(info.ledgerReceiveFee) +
                                            " " +
                                            _vm._s(
                                              info.ledgerReceiveModel == 1
                                                ? "%"
                                                : "元"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                              info.ledgerReceiveStrategy == 1
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "show-detail",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDetails(info)
                                          },
                                        },
                                      },
                                      [_vm._v(" 查看明细 ")]
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          : [
                              _c("div", { staticClass: "no-data" }, [
                                _vm._v("分账未开启"),
                              ]),
                            ],
                      ],
                      2
                    )
                  }
                ),
                0
              ),
            ]),
          ]
        )
      }),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              ref: "dialogVisible",
              attrs: {
                title: "按线路分账明细",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-bottom": "20px" },
                  attrs: {
                    data: _vm.lineDetailInfo.wechatMchLedgerReceiveLines,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "lineName",
                      label: "线路名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ledgerReceiveModel",
                      label: "分账模式",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.ledgerReceiveModel == 1
                                      ? "按比例分账"
                                      : "按金额分账"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2163459651
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ledgerReceiveFee",
                      label: "分账金额/分账比例",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.ledgerReceiveModel == 1
                                      ? scope.row.ledgerReceiveFee + "%"
                                      : scope.row.ledgerReceiveFee + "元"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2636543926
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footor" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "cancel" },
                      on: { click: () => (_vm.dialogVisible = false) },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: () => (_vm.dialogVisible = false) },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("addLedgerDialog", { ref: "addLedgerDialogRef" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }