<template>
  <!-- 平台分账查看明细 -->
  <div class="platformDetails-page">
    <div class="btn-box" @click="backFun">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <!-- 分账方 -->
    <div class="ledger-box content-box">
      <div class="ledger-box-header">
        <span class="tip-text">分账方</span>
        <div>
          <el-button
            v-if="listFind('更新分账比例')"
            size="small"
            type="primary"
            @click="updateProportionFun"
            >更新分账比例</el-button
          >
          <el-button
            v-if="listFind('添加分账接收方')"
            size="small"
            type="primary"
            @click="addLedgerFun"
            >添加分账接收方</el-button
          >
        </div>
      </div>
      <div class="ledger-content-outFlex">
        <div
          v-for="(item, index) in ledgerList"
          :key="index"
          class="ledger-content"
        >
          <div>{{ item.label }}</div>
          <div v-if="item.value == 'maxLedgerRatio'">
            {{ detailsInfo[item.value] + "%" }}
          </div>
          <div v-else>{{ detailsInfo[item.value] }}</div>
        </div>
      </div>
    </div>
    <!-- 分账接收方 -->
    <div
      class="receiver-box content-box"
      v-for="(item, index) in receiverList"
      :key="index"
    >
      <div class="ledger-box-header">
        <span class="tip-text">分账接收方</span>
        <div>
          <el-button
            v-if="listFind('编辑')"
            size="small"
            type="primary"
            @click="editReceiverFun(item)"
            >编辑</el-button
          >
          <el-button
            v-if="listFind('删除')"
            size="small"
            type="primary"
            @click="deleteReceiverFun(item)"
            >删除</el-button
          >
        </div>
      </div>
      <div class="receiver-content">
        <!-- 基本信息 -->
        <h4>基本信息</h4>
        <div class="basic-information-top">
          <div>
            <span>商户全称：</span>
            <span>{{ item.ledgerReceiveName }}</span>
          </div>
          <div>
            <span>商户号：</span>
            <span>{{ item.ledgerReceiveId }}</span>
          </div>
        </div>
        <h4>分账信息</h4>
        <div class="dimension-information-flex">
          <div
            v-for="(info, infoIndex) in item.kkWechatLedgerReceiveBusinessVOS"
            :key="infoIndex"
            class="dimension-information"
          >
            <p class="name">{{ analyzeName(info.ledgerBusiness) }}</p>
            <template v-if="info.status == 1">
              <div>
                <span>分账策略：</span>
                <span>{{
                  getLedgerReceiveStrategy(info.ledgerReceiveStrategy)
                }}</span>
              </div>
              <!-- 统一分账 -->
              <template v-if="info.ledgerReceiveStrategy == 0">
                <div>
                  <span>分账模式：</span>
                  <span>{{
                    getLedgerReceiveModel(info.ledgerReceiveModel)
                  }}</span>
                </div>
                <div>
                  <span>{{
                    info.ledgerReceiveModel == 1 ? "比例分账：" : "分账金额："
                  }}</span>
                  <span
                    >{{ info.ledgerReceiveFee }}
                    {{ info.ledgerReceiveModel == 1 ? "%" : "元" }}
                  </span>
                </div>
              </template>
              <!-- 按照线路分账 -->
              <template v-if="info.ledgerReceiveStrategy == 1">
                <div class="show-detail" @click="showDetails(info)">
                  查看明细
                </div>
              </template>
            </template>
            <template v-else>
              <div class="no-data">分账未开启</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible"
      title="按线路分账明细"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      ref="dialogVisible"
      width="40%"
    >
      <el-table
        :data="lineDetailInfo.wechatMchLedgerReceiveLines"
        border
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column prop="lineName" label="线路名称" align="center">
        </el-table-column>
        <el-table-column
          prop="ledgerReceiveModel"
          label="分账模式"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.ledgerReceiveModel == 1 ? "按比例分账" : "按金额分账"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="ledgerReceiveFee"
          label="分账金额/分账比例"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.ledgerReceiveModel == 1
                ? scope.row.ledgerReceiveFee + "%"
                : scope.row.ledgerReceiveFee + "元"
            }}
          </template>
        </el-table-column>
      </el-table>
      <div class="footor">
        <el-button
          size="small"
          type="cancel"
          @click="() => (dialogVisible = false)"
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="() => (dialogVisible = false)"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <addLedgerDialog ref="addLedgerDialogRef"></addLedgerDialog>
  </div>
</template>

<script>
import addLedgerDialog from "./addLedgerDialog.vue";
import {
  deleteWechatMchLedgerReceiveByIdAPI,
  queryWechatMchLedgerInfoAPI,
  updateWechatMaxLedgerRatioByIdAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {
    addLedgerDialog,
  },
  props: {
    detailsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      detailsInfo: {},
      ledgerBusinessList: [
        {
          label: 1,
          name: "城际专车",
          ledgerReceiveStrategyKey: "ledgerReceiveStrategy",
          ledgerReceiveModelKey: "ledgerReceiveModel",
          ledgerReceiveFeeKey: "ledgerReceiveFee",
        },
        {
          label: 2,
          name: "汽车票",
          ledgerReceiveStrategyKey: "carLedgerReceiveStrategy",
          ledgerReceiveModelKey: "carLedgerReceiveModel",
          ledgerReceiveFeeKey: "carLedgerReceiveFee",
        },
        {
          label: 5,
          name: "租车",
          ledgerReceiveStrategyKey: "ledgerReceiveStrategy",
          ledgerReceiveModelKey: "ledgerReceiveModel",
          ledgerReceiveFeeKey: "ledgerReceiveFee",
        },
        {
          label: 4,
          name: "包车",
          ledgerReceiveStrategyKey: "carLedgerReceiveStrategy",
          ledgerReceiveModelKey: "carLedgerReceiveModel",
          ledgerReceiveFeeKey: "carLedgerReceiveFee",
        },
      ], //分账业务
      ledgerTypeList: [
        { label: "24小时后分账", value: 0 },
        { label: "立即分账", value: 1 },
        { label: "不分账", value: -1 },
      ], //分账方式
      ledgerReceiveModelList: [
        { label: "按比例分账", value: 1 },
        { label: "按金额分账", value: 2 },
      ], //分账模式
      ledgerReceiveStrategyList: [
        { label: "统一分账", value: 0 },
        { label: "按线路分账", value: 1 },
      ], //分账策略
      relationTypeList: [{ label: "服务商", value: "SERVICE_PROVIDER" }], //分账方关系类型
      ledgerList: [
        { label: "分账方：", value: "mchName" },
        { label: "分账方商户号：", value: "mchId" },
        { label: "分账比例：", value: "maxLedgerRatio" },
        // { label: "分账业务：", value: "ledgerBusiness" },
      ], //分账方
      cjzcTableData: [],
      dialogVisible: false,
      lineDetailInfo: null,
    };
  },
  filters: {},
  computed: {
    receiverList() {
      return this.detailsInfo.mchLedgerReceives || [];
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.detailsInfo = JSON.parse(JSON.stringify(this.detailsData));
  },
  methods: {
    /**
     * 城际专车按照线路分账明细弹窗显示
     */
    showDetails(info) {
      this.dialogVisible = true;
      this.lineDetailInfo = info;
    },
    /**
     * @description 添加分账接收方
     */
    addLedgerFun() {
      this.$refs.addLedgerDialogRef.openDialogFun(
        "添加分账接收方",
        this.detailsInfo
      );
    },
    /**
     * @description 更新分账比例
     */
    updateProportionFun() {
      updateWechatMaxLedgerRatioByIdAPI({ id: this.detailsInfo.id }).then(
        (res) => {
          if (res.code === "SUCCESS" && res.data) {
            this.$message.success("更新成功");
          }
          this.getQueryWechatMchLedgerInfo();
        }
      );
    },

    /**
     * @description 编辑分账接收方
     */
    editReceiverFun(row) {
      row.mchName = this.detailsInfo.mchName;
      row.mchId = this.detailsInfo.mchId;
      row.appId = this.detailsInfo.appId;
      row.channelNo = this.detailsInfo.channelNo;
      row.maxLedgerRatio = this.detailsInfo.maxLedgerRatio;
      this.$refs.addLedgerDialogRef.openDialogFun(
        "编辑分账接收方",
        JSON.parse(JSON.stringify(row))
      );
    },
    /**
     * @description 删除分账接收方
     */
    deleteReceiverFun(item) {
      this.$confirm("删除后不再对该接收方进行分账，确认删除？", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [];
          item.kkWechatLedgerReceiveBusinessVOS.map((i) => {
            arr.push(i.id);
          });
          deleteWechatMchLedgerReceiveByIdAPI({ ids: arr.join(",") }).then(
            (res) => {
              if (res.code === "SUCCESS" && res.data) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getQueryWechatMchLedgerInfo();
              }
            }
          );
        })
        .catch(() => {});
    },
    /**
     * @description 获取详情
     */
    getQueryWechatMchLedgerInfo() {
      queryWechatMchLedgerInfoAPI({ id: this.detailsInfo.id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.detailsInfo = res.data;
        }
      });
    },
    /**
     * @description 返回按钮
     */
    backFun() {
      this.$parent.isShowDetails = false;
      this.$parent.getQueryWechatMchLedgerPage();
    },
    /**
     * @description 获取分账方关系类型
     */
    getRelationType(val) {
      return this.relationTypeList.find((item) => {
        return item.value == val;
      })?.label;
    },
    /**
     * @description 获取分账模式
     */
    getLedgerReceiveModel(val) {
      return this.ledgerReceiveModelList.find((item) => {
        return item.value == val;
      })?.label;
    },
    /**
     * @description 获取分账策略
     */
    getLedgerReceiveStrategy(val) {
      return this.ledgerReceiveStrategyList.find((item) => {
        return item.value == val;
      })?.label;
    },
    /**
     * @description 解析分账业务类型
     */
    analyzeName(id) {
      return this.ledgerBusinessList.find((item) => item.label == id)?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.footor {
  text-align: right;
}
.platformDetails-page {
  .btn-box {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  .content-box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
    .tip-text {
      font-weight: 600;
      font-size: 16px;
      margin-right: 10px;
    }
    .ledger-box-header {
      display: flex;
      justify-content: space-between;
    }
  }
  .ledger-box {
    margin-bottom: 20px;

    .ledger-content-outFlex {
      padding: 20px 0 20px 40px;
      display: flex;
    }
    .ledger-content {
      font-size: 14px;
      display: flex;
      align-items: center;
      flex: 0.33;
    }
  }
  .receiver-box {
    .receiver-content {
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 14px;
      .basic-information-top {
        display: flex;
        div {
          flex-grow: 0.33;
          line-height: 36px;
          margin-left: 10px;
        }
      }
      .dimension-information-flex {
        display: flex;
        .no-data {
          font-size: 24px;
          color: #999999;
          font-weight: bold;
          text-align: center;
          line-height: 108px;
        }
        .show-detail {
          cursor: pointer;
          color: #0000ff;
        }
      }
      .dimension-information {
        flex: 0.25;
        background-color: #f2f2f2;
        margin: 0 10px;
        padding: 16px;
        .name {
          font-weight: bold;
          margin-bottom: 20px;
        }
        div {
          flex-grow: 1;
          flex-basis: min-content;
          line-height: 36px;
        }
      }
    }
  }
}
</style>
