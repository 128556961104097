var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "platformConfiguration-page" },
    [
      !_vm.isShowDetails
        ? [
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c("Table", {
                  attrs: {
                    "table-data": _vm.tableData,
                    "title-name": _vm.titleName,
                    operation: "",
                    "operation-width": "80",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.listFind("查看")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      sort: "primary",
                                      disabled: !scope.scopeRow.merchantType,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.seeFun(scope.scopeRow)
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1788694015
                  ),
                }),
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    "page-size": _vm.params.pageSize,
                    "current-page": _vm.params.currentPage,
                  },
                  on: {
                    "size-change": _vm.sizeChangeFun,
                    "current-change": _vm.currentChangeFun,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("newAddDialog", { ref: "newAddDialogRef" }),
      _c("addLedgerDialog", { ref: "addLedgerDialogRef" }),
      _vm.isShowDetails
        ? _c("platformDetails", { attrs: { detailsData: _vm.detailsData } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }