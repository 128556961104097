<template>
  <!-- 平台分账配置 -->
  <div class="platformConfiguration-page">
    <template v-if="!isShowDetails">
      <div class="table-box">
        <Table
          :table-data="tableData"
          :title-name="titleName"
          operation
          operation-width="80"
        >
          <template slot-scope="scope">
            <el-button
              v-if="listFind('查看')"
              type="text"
              size="small"
              sort="primary"
              :disabled="!scope.scopeRow.merchantType"
              @click="seeFun(scope.scopeRow)"
              >查看</el-button
            >
          </template>
        </Table>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="params.pageSize"
          :current-page="params.currentPage"
          @size-change="sizeChangeFun"
          @current-change="currentChangeFun"
        ></el-pagination>
      </div>
    </template>
    <newAddDialog ref="newAddDialogRef"></newAddDialog>
    <addLedgerDialog ref="addLedgerDialogRef"></addLedgerDialog>
    <platformDetails
      v-if="isShowDetails"
      :detailsData="detailsData"
    ></platformDetails>
  </div>
</template>

<script>
import newAddDialog from "./components/newAddDialog.vue";
import addLedgerDialog from "./components/addLedgerDialog.vue";
import platformDetails from "./components/platformDetails.vue";
import {
  queryWechatMchLedgerPageAPI,
  queryWechatMchLedgerInfoAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {
    newAddDialog,
    addLedgerDialog,
    platformDetails,
  },
  props: {},
  data() {
    return {
      total: 0,
      params: {
        pageSize: 10,
        currentPage: 1,
      },
      isShowDetails: false,
      detailsData: {},
      ledgerTypeList: [
        { label: "24小时后分账", value: 0 },
        { label: "立即分账", value: 1 },
        { label: "不分账", value: -1 },
      ], //分账方式
      ledgerBusinessList: [
        { label: 1, name: "城际专车" },
        { label: 2, name: "汽车票" },
        { label: 4, name: "包车" },
        { label: 5, name: "租车" },
      ], //分账业务 1-城际专车 2-汽车票 4-包车 5-租车
      tableData: [],
      titleName: [
        {
          title: "渠道",
          props: "channelName",
        },
        {
          title: "商户号",
          props: "mchId",
        },
        {
          title: "商户简称",
          props: "mchName",
        },
        {
          title: "商户配置",
          props: "wechatType",
          SpecialJudgment: (res) => {
            return res ? "独立配置" : "默认配置";
          },
        },
        {
          title: "商户类型",
          props: "merchantType",
          SpecialJudgment: (res) => {
            return res ? "特约商户" : "普通商户";
          },
        },
        {
          title: "分账状态",
          SpecialJudgment: (res) => {
            return res.merchantType
              ? res.ledgerRatioReceiveCount > 0
                ? "已分账"
                : "未分账"
              : "不支持分账";
          },
        },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {
    this.getQueryWechatMchLedgerPage();
  },
  mounted() {},
  methods: {
    /**
     * @description 获取列表数据
     */
    getQueryWechatMchLedgerPage() {
      queryWechatMchLedgerPageAPI(this.params).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 当前页码改变
    currentChangeFun(page) {
      this.params.currentPage = page;
    },
    // 当前条数改变
    sizeChangeFun(size) {
      this.params.pageSize = size;
    },
    /**
     * @description 查看
     */
    seeFun(row) {
      queryWechatMchLedgerInfoAPI({ id: row.id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.detailsData = res.data;
          this.isShowDetails = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.platformConfiguration-page {
  background-color: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  padding: 16px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  .btn-box {
    margin-bottom: 20px;
  }
}
</style>
